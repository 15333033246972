import React from 'react'

import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import {
  SxProps,
  TextField,
  Theme,
  styled,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  CircularProgress,
  IconButton,
  Typography,
  Box,
  Stack,
  Divider
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  fetchAnalyzeInfoByAnalysisId,
  fetchSampleById,
  Sample,
  searchChinahpo,
  translate,
  TranslateDto
} from '@/@core/services/app.service'
import { useMutation, useQuery } from 'react-query'
import Sync from 'mdi-material-ui/Sync'
import { pointer } from 'd3'
import { isJson, unique } from '@/@core/utils/utils'
import { useRouter } from 'next/router'
import { Cached, Delete, DeleteOutline, File } from 'mdi-material-ui'
import { CopyAll } from '@mui/icons-material'
import PositionedSnackbar from '../message'
import { copyToClipboard } from '@/@core/layouts/utils'
import { StringifyOptions } from 'querystring'

interface Props {
  termList: any[]
  fn?: (query: Film[]) => Film[]
  sx?: SxProps<Theme>
  isBlack?: boolean
  sampleId?: any
  disabledHp?: boolean
  disabledOMIM?: boolean
  disabledRefersh?: boolean
  type?: string
}

export interface Film {
  definition_cn?: string
  definition_en?: string
  hpoId?: string
  name_cn?: string
  name_en?: string
  altTitle?: string
  cnTitle?: string
  incTitle?: string
  mimNumber?: number
  preTitle?: string
  prefix?: string
  title: string
}

export interface Data {
  phenotypeList: {
    data: Phenotype[]
  }
  OMIMList: {
    data: OMIM[]
  }
}

export interface Phenotype {
  definition_cn: string
  definition_en: string
  hpoId: string
  name_cn: string
  name_en: string
}

export interface OMIM {
  altTitle: string
  cnTitle: string
  incTitle: string
  mimNumber: number
  preTitle: string
  prefix: string
}

const ChipColor = styled(Chip)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: 'white'
  },
  userSelect: 'text'
}))

const color = 'black'

const AutocompleteColor = styled(Autocomplete)(({ theme }) => ({
  // '& .MuiOutlinedInput-notchedOutline': {
  //   borderColor: `${color} !important`
  // },
  // '& .MuiInputBase-root': {
  //   borderRadius: 0,
  //   color: color
  // },
  // '& .MuiChip-root': {
  //   color: `${color} !important`
  // },
  // '& .MuiSvgIcon-root': {
  //   borderRadius: 0,
  //   color: `${color} !important`
  // }
}))

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))

const ChinahpoSearch = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState<string>('')
  const [options, setOptions] = React.useState<readonly Film[]>([])
  const loading = open && options.length === 0
  const messageRef = React.useRef<any>()

  const router = useRouter()

  const { id } = router.query

  const pageType = router.asPath.includes('/samples') ? 'sample' : 'analyze'

  // const [termList, setTermList] = React.useState<any>([])
  const searchChinahpos = useMutation({
    mutationFn: async (data: string) => {
      if (props.disabledHp)
        return await searchChinahpo({
          like: data,
          type: 2
        })
      else if (props.disabledOMIM)
        return await searchChinahpo({
          like: data,
          type: 1
        })
      else
        return await searchChinahpo({
          like: data
        })
    },
    onSuccess: (data: Data) => {
      //post返回的结果
      const list = data.phenotypeList.data
        .filter((item) => item.hpoId)
        .map((items) => {
          return {
            ...items,
            title: `${items.hpoId}${items.name_en}(${items.name_cn})`
          }
        })
      const list2 = data.OMIMList.data
        .filter((item) => item.mimNumber)
        .map((items) => {
          return {
            ...items,
            title: `OMIM:${items.mimNumber}"【${items.cnTitle}(${items.preTitle})】`
          }
        })

      setOptions([...list, ...list2])
    }
  })

  const getTerms = (terms: any) => {
    if (terms) {
      if (Array.isArray(terms)) return terms
      else if (isJson(terms)) return JSON.parse(terms)
      else return []
    } else return []
  }

  const getphenotypes = useMutation({
    mutationFn: async () => await fetchSampleById(props.sampleId),
    onSuccess: (data: Sample) => {
      props.fn && props.fn([...getTerms(data.terms)])
    }
  })

  const getphenotypesByAnalysisId = useMutation({
    mutationFn: async () => await fetchAnalyzeInfoByAnalysisId(props.sampleId),
    onSuccess: (data: any) => {
      if (data && data.list && Array.isArray(data.list)) {
        const terms: any[] = []

        data.list.map((items: { analysisSamples: { terms: any }[] }) => {
          items.analysisSamples &&
            items.analysisSamples.map((item: { terms: any }) => {
              if (item && item.terms) terms.push(getTerms(item.terms))
            })
        })

        props.fn && props.fn(unique(terms.flat(), 'title'))
      }
    }
  })

  const Translate = useMutation({
    mutationFn: async (data: TranslateDto) => await translate(data),
    onSuccess: (data, params, context) => {
      //post返回的结果
      if (params.sourceLanguage === 'zh') {
        const param = {
          definition_cn: '',
          definition_en: '',
          hpoId: '',
          name_cn: search,
          name_en: data,
          altTitle: '',
          cnTitle: '',
          incTitle: '',
          mimNumber: 0,
          preTitle: data,
          prefix: '',
          title: `${data}(${search})`
        }

        props.fn && props.fn([...props.termList, param])
      }
    }
  })

  React.useEffect(() => {
    let active = true

    searchChinahpos.mutate(search)

    return () => {
      active = false
    }
  }, [search])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  if (props.isBlack) {
    return (
      <React.Fragment>
        <AutocompleteColor
          id="asynchronous-demo"
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disableCloseOnSelect
          disableClearable
          sx={props.sx}
          isOptionEqualToValue={(option: any, value: any) =>
            option.title === value.title
          }
          getOptionLabel={(option: any) => option.title}
          options={options}
          loading={loading}
          renderTags={
            ((value: readonly Film[], getTagProps: any) =>
              value.map((option: Film, index: number) => (
                <HtmlTooltip title={option.title} placement={'top-start'}>
                  <ChipColor
                    variant="outlined"
                    label={option.title}
                    {...getTagProps({ index })}
                    key={index}
                    color={'secondary'}
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white'
                    }}
                  />
                </HtmlTooltip>
              ))) as any
          }
          value={props.termList}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('Enter hpo, omim, and click Save')}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              minRows={4}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.which === 13) {
                  // 处理回车键事件
                  const sourceLanguage = 'zh',
                    targetLanguage = 'en'
                  const params: TranslateDto = {
                    sourceLanguage,
                    targetLanguage,
                    sourceText: search
                  }

                  Translate.mutateAsync(params)

                  e.preventDefault()

                  return false
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: props.disabledRefersh ? null : (
                  <>
                    <Sync
                      onClick={() => {
                        if (pageType === 'sample') getphenotypes.mutateAsync()
                        else getphenotypesByAnalysisId.mutateAsync()
                      }}
                      sx={{ cursor: 'pointer' }}
                    />

                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              multiline
            />
          )}
          onChange={(e: any, v: any) => {
            if (e.code === 'Backspace') return

            props.fn && props.fn([...v])
          }}
        />

        <PositionedSnackbar ref={messageRef} />
      </React.Fragment>
    )
  } else if (props.type && props.type === 'patient') {
    return (
      <React.Fragment>
        <AutocompleteColor
          id="asynchronous-demo"
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disableCloseOnSelect
          disableClearable
          sx={props.sx}
          isOptionEqualToValue={(option: any, value: any) =>
            option.title === value.title
          }
          getOptionLabel={(option: any) => option.title}
          options={options}
          loading={loading}
          renderTags={
            ((value: readonly Film[], getTagProps: any) =>
              value.map((option: Film, index: number) => '')) as any
          }
          value={props.termList}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('Enter hpo, omim, and click Save')}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              rows={1}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.which === 13) {
                  // 处理回车键事件
                  const sourceLanguage = 'zh',
                    targetLanguage = 'en'
                  const params: TranslateDto = {
                    sourceLanguage,
                    targetLanguage,
                    sourceText: search
                  }

                  Translate.mutateAsync(params)

                  e.preventDefault()

                  return false
                }
              }}
              multiline
            />
          )}
          onChange={(e: any, v: any) => {
            if (e.code === 'Backspace') return

            props.fn && props.fn([...v])
          }}
        />

        {/* {props.termList && props.termList.length ? (
          <React.Fragment>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>{'Terms List'}</Typography>

              <Box>
                <IconButton onClick={() => props.fn && props.fn([])}>
                  <Cached />
                </IconButton>

                <IconButton
                  onClick={async () => {
                    try {
                      await copyToClipboard(
                        props.termList.map((option) => option.title).join('\n')
                      )

                      messageRef?.current?.handleClick(
                        t('Successful copy'),
                        'success'
                      )
                    } catch (error) {
                      console.error(error)

                      messageRef?.current?.handleClick(
                        t('Failed to copy'),
                        'error'
                      )
                    }
                  }}
                >
                  <CopyAll />
                </IconButton>
              </Box>
            </Stack>

            <Divider />
          </React.Fragment>
        ) : (
          ''
        )} */}

        <Box maxHeight={200} overflow="auto">
          {props.termList.map((item) => (
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography color={'primary.main'} py={1}>
                {item.title}
              </Typography>

              <IconButton
                onClick={() => {
                  props.fn &&
                    props.fn(
                      props.termList.filter((i) => i.title !== item.title)
                    )
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Stack>
          ))}
        </Box>

        <PositionedSnackbar ref={messageRef} />
      </React.Fragment>
    )
  } else
    return (
      <React.Fragment>
        <Autocomplete
          id="asynchronous-demo"
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disableClearable
          sx={props.sx}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option.title}
          options={options}
          loading={loading}
          renderTags={(value: readonly Film[], getTagProps) =>
            value.map((option: Film, index: number) => (
              <ChipColor
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
                key={index}
                color={'secondary'}
                sx={{
                  bgcolor: 'primary.main',
                  color: 'white',
                  userSelect: 'text'
                }}
                icon={
                  <IconButton
                    size="small"
                    onClick={async (e) => {
                      try {
                        await copyToClipboard(
                          option.name_en || option.preTitle,
                          e
                        )

                        messageRef?.current?.handleClick(
                          t('Successful copy'),
                          'success'
                        )
                      } catch (error) {
                        console.error(error)

                        messageRef?.current?.handleClick(
                          t('Failed to copy'),
                          'error'
                        )
                      }
                    }}
                  >
                    <CopyAll />
                  </IconButton>
                }
              />
            ))
          }
          value={props.termList}
          multiple
          renderInput={(params) => (
            <TextField
              placeholder={t('Enter hpo, omim, and click Save')}
              {...params}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              minRows={4}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.which === 13) {
                  // 处理回车键事件
                  const sourceLanguage = 'zh',
                    targetLanguage = 'en'
                  const params: TranslateDto = {
                    sourceLanguage,
                    targetLanguage,
                    sourceText: search
                  }

                  Translate.mutateAsync(params)

                  e.preventDefault()

                  return false
                }
              }}
              multiline
            />
          )}
          onChange={(e: any, v) => {
            if (e.code === 'Backspace') return

            props.fn && props.fn([...v])
          }}
        />

        <PositionedSnackbar ref={messageRef} />
      </React.Fragment>
    )
})

export default ChinahpoSearch
